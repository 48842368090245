@import url("https://fonts.googleapis.com/css2?family=Arimo&family=Bitter&family=Raleway&display=swap");

:root {
    --color-bg: #0a0a0a;
    --color-font: #ffffff;
    --color-secondary: #bd4830;

    --font-main: "Arimo", sans-serif;
    --font-ru: "Arimo", sans-serif;
    --font-he: "Bitter", serif;
    --font-en: "Raleway", sans-serif;

    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}
* {
    box-sizing: border-box;
}

* {
    --sb-track-color: #ffffff;
    --sb-thumb-color: #bd4830;
    --sb-size: 14px;
}

*::-webkit-scrollbar {
    width: var(--sb-size);
}

*::-webkit-scrollbar-track {
    background: var(--sb-track-color);
    border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
    background: var(--sb-thumb-color);
    border-radius: 10px;
}

@supports not selector(::-webkit-scrollbar) {
    * {
        scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
    }
}

.full-page {
    height: 100vh;
    background-color: var(--color-bg);
}

.photo-slide {
    width: 300px;
    height: 600px;
}

/* Mobile  */
@media only screen and (min-width: 320px) and (max-width: 480px) {
    .full-page {
        height: 100svh;
    }
}

/* // iPad + Tablet screen */
@media only screen and (min-width: 481px) and (max-width: 768px) {
    .full-page {
        height: 100svh;
        background-color: var(--color-bg);
    }
}

/* // Small screen + Laptop screen */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .full-page {
        height: 100svh;
        background-color: var(--color-bg);
    }
}

/* //  Desktops + large screen */
@media only screen and (min-width: 1025px) and (max-width: 1920px) {
    .full-page {
        height: 100vh;
        width: 100vw;
        background-color: var(--color-bg);
    }
}

/* // Extra large + TV screen */
@media only screen and (min-width: 1921px) {
    .full-page {
        height: 100vh;
        width: 100vw;
        background-color: var(--color-bg);
    }
}
